import React from 'react'
import img from '../../images/hifu-cialo-zmiana.webp'
import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import Fade from 'react-reveal'
import {Seo} from '../../components/Seo'

const HIFUTwarz = () => {
    return (
        <>
            <Layout>
                <Seo
                    title='HIFU | Modelowanie Sylwetki'
                    description='HIFU to zabieg, który jest nazywany nieinwazyjnym liftingiem bez
          użycia skalpela. Jego pełna nazwa to High Intensity Focused
          Ultrasound. Zabieg jest alternatywą dla osób, które chcą wyglądać
          młodo jednak nie akceptują lub unikają zabiegów z użyciem skalpela.'
                    url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-cialo/hifu'
                />

                <div className='page-container'>
                    <div className='page'>
                        <div className='content-1'>
                            <h1>HIFU</h1>
                            <div className='section'>
                                <div className='image-container'>
                                    <img
                                        src={img}
                                        width='1000px'
                                        height='667px'
                                        alt='hifu zdjęcie'
                                    ></img>
                                </div>

                                <div className='text'>
                                    <p>
                                        HIFU to zabieg, który jest nazywany nieinwazyjnym
                                        liftingiem bez użycia skalpela. Jego pełna nazwa
                                        to High Intensity Focused Ultrasound. Zabieg jest
                                        alternatywą dla osób, które chcą wyglądać młodo
                                        jednak nie akceptują lub unikają zabiegów z
                                        użyciem skalpela.
                                    </p>
                                    <p>
                                        Zabieg HIFU działa przeciwstarzeniowo, zmniejsza
                                        powstające wraz z wiekiem zmarszczki czy
                                        zwiotczenia skórne. HIFU 3D to działanie
                                        skoncentrowaną wiązką ultradźwięków o różnej
                                        częstotliwości i głębokości, które nie uszkadza
                                        skóry a koncentruje się w głębokich warstwach
                                        skóry lub w tkance podskórnej. Dochodzi do
                                        podgrzania tkanek podskórnych powodując ich
                                        koagulację. Efektem tego działania jest lifting
                                        skóry oraz produkcja nowego kolagenu. Zabieg
                                        poprawia owal twarzy, wyostrza kontur, wyraźnie
                                        liftinguje skórę.
                                    </p>
                                    <p>
                                        Nasze urządzenie pozwala na przeprowadzanie
                                        zabiegów z użyciem wieloliniowych kartridży.
                                        Dzięki temu możliwe jest uzyskanie jeszcze
                                        lepszego pokrycia pola zabiegowego przy mniejszej
                                        gęstości „strzałów”. Jest to idealne rozwiązanie
                                        dla osób chcących znacząco poprawić swój wygląd
                                        bez metod inwazyjnych.
                                    </p>
                                    <div className='cennik'>
                                        <span>CENNIK</span>
                                        <h2>HIFU</h2>
                                        <ul>
                                            <li>
                                                <p>HIFU PELIKANY - POJEDYNCZY ZABIEG</p>
                                                <span className='prices'>1300 zł</span>
                                            </li>
                                            <li>
                                                <p>HIFU BUŁECZKI - POJEDYNCZY ZABIEG</p>
                                                <span className='prices'>1000 zł</span>
                                            </li>
                                            <li>
                                                <p>HIFU BRZUCH - POJEDYNCZY ZABIEG</p>
                                                <span className='prices'>2000 zł</span>
                                            </li>
                                            <li>
                                                <p>
                                                    HIFU BRZUCH + BOCZKI - POJEDYNCZY
                                                    ZABIEG
                                                </p>
                                                <span className='prices'>3000 zł</span>
                                            </li>
                                            <li>
                                                <p>
                                                    HIFU UDA WE. LUB ZEW. - POJEDYNCZY
                                                    ZABIEG
                                                </p>
                                                <span className='prices'>1700 zł</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Fade left>
                            <Link to='/kontakt'>
                                <button>Umów Wizytę</button>
                            </Link>
                        </Fade>
                    </div>
                </div>
            </Layout>
            <div className='copyright-notice'>
                <a href='https://www.freepik.com/free-photo/woman-making-beauty-procedures-beauty-salon_5578474.htm?query=woman-cosmetologist-making-beauty-procedures'>
                    Image by senivpetro
                </a>{' '}
                <div>on Freepik</div>
            </div>
        </>
    )
}

export default HIFUTwarz
